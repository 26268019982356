<template>
  <v-dialog
    width="auto"
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card :loading="loading">
      <v-toolbar color="info" dark
        ><v-toolbar-title>{{ event }}</v-toolbar-title>

        <v-toolbar-items>
          <PersonItem :value="overview.person" />
        </v-toolbar-items>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="ma-0 pa-0">
        <v-data-table
          :headers="headers"
          :items="overview.items"
          disable-sort
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.person="{ item }">
            <PersonItem small :value="item.person" />
          </template>

          <template
            v-for="column in overview.columns"
            v-slot:[`item.${column.id}`]="{ item }"
          >
            <CurrencyValue
              v-if="item.hasOwnProperty(column.id)"
              :value="item[column.id]"
              :key="'slot' + column.id"
            />
            <span v-else :key="'slot' + column.id">–</span>
          </template>

          <template v-slot:item.total="{ item }">
            <span class="grey--text"
              ><CurrencyValue :value="item.total"
            /></span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer /><v-btn text @click="dialog = false"
          >schliessen</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import CurrencyValue from "@/components/CurrencyValue.vue";
import PersonItem from "@/components/PersonItem.vue";

export default {
  components: { CurrencyValue, PersonItem },
  props: ["id"],
  data: () => ({
    overview: { student: {}, balance: 0, columns: [], items: [] },
    loading: false,
    dialog: true,
    headers: [{ text: "Person", value: "person" }],
  }),
  computed: {
    event() {
      return this.overview.columns.length > 0
        ? this.overview.columns[0].event
        : "Anlass";
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$router.push({
          name: "FinanceEvents",
        });
      }
    },
    id() {
      this.fetchData();
    },
  },

  methods: {
    async fetchData() {
      this.loading = true;
      this.overview = await this.apiGet({
        resource: "finance/eventoverview",
        id: this.id,
      });
      for (const item of this.overview.items) {
        let total = 0;
        for (const column of this.overview.columns) {
          if (column.id in item) {
            total = total + item[column.id];
          }
        }
        item.total = total;
      }
      for (const column of this.overview.columns) {
        this.headers.push({
          text: column.description,
          value: "" + column.id,
        });
      }
      this.headers.push({
        text: "Total",
        value: "total",
      });
      this.loading = false;
    },
  },

  async created() {
    this.fetchData();
  },
};
</script>
